/* You can add global styles to this file, and also import other style files */
body {
  display: block !important;
  height: 100% !important;
}

.edu-monitor {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

.edu {
  color: #0056b3;
}

.monitor {
  color: #ff9900;
}

.edu-monitor:hover {
  filter: brightness(120%);
  cursor: pointer;
}

.error-toast {
  --mdc-snackbar-container-color: #e53935 !important;

  .mat-mdc-button.mat-mdc-snack-bar-action {
    color: white !important;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
